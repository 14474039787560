import NextLink from 'next/link'
import { twMerge } from 'tailwind-merge'

type LinkProps = React.ComponentProps<typeof NextLink>

const Link = ({ children, className, target, ...otherProps }: LinkProps) => {
  return (
    <NextLink
      className={twMerge(
        'text-grayscale-800 select-none text-sm no-underline underline-offset-2 hover:underline',
        className,
      )}
      target={target ?? '_blank'}
      {...otherProps}
    >
      {children}
    </NextLink>
  )
}

export default Link
