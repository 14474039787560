import { twMerge } from 'tailwind-merge'
import TAILWIND_CONFIG from 'tailwind.config'

const COLORS = TAILWIND_CONFIG.theme.extend.colors
type ColorKeysType = keyof typeof COLORS

type Props = {
  percentage: string | number
  height?: string | number
  color?: ColorKeysType
  backgroundColor?: ColorKeysType
}
const ProgressBar = ({
  percentage,
  height = 8,
  color = 'peach-500',
  backgroundColor = 'grayscale-100',
}: Props) => {
  return (
    <div
      aria-label='progress-bar'
      className={twMerge(
        'relative w-full overflow-hidden rounded-lg',
        'before:absolute before:left-0 before:top-0 before:block before:h-full   before:transition-all before:duration-300 before:ease-in-out before:content-[""]',
        'h-[var(--wu-progress-height)] bg-[var(--wu-progress-bg-color)]',
        'before:w-[var(--wu-progress-width)] before:bg-[var(--wu-progress-color)]',
      )}
      style={{
        // @ts-expect-error, add dynamic css variables in this div scope;
        '--wu-progress-width':
          typeof percentage === 'string'
            ? percentage
            : `${Number.isFinite(percentage) ? percentage : 0}%`,
        '--wu-progress-height': typeof height === 'string' ? height : `${height}px`,
        '--wu-progress-color': COLORS[color],
        '--wu-progress-bg-color': COLORS[backgroundColor],
      }}
    />
  )
}

export type { Props as ProgressBarProps }
export default ProgressBar
